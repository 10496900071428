import React from "react";
import { SubscriptionsPage } from "./SubscriptionsPage";
import { Subscribe } from "./SubscribePage";
import config from "../config";
import Axios from "axios";
import { ISubscription } from "../../server/subscription/ISubscription";

export class Subscriptions extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      subs: [],
      usedCredits: 0
    };

    this.getSubs = this.getSubs.bind(this);
  }

  async componentDidMount() {
    await this.getSubs();
  }

  async getSubs() {
    try {
      const subs = await Axios.request({
        method: "GET",
        url: config().BASE_API + "/subscription",
        headers: {
          Authorization: `Bearer ${this.props.auth.getAccessToken()}`
        },
        responseType: "json"
      });

      console.log("subs", subs.data);

      this.setState({
        subs: subs.data,
        usedCredits: subs.data.reduce((sum: number, sub: ISubscription)=>{
          sum += sub.credits;
          return sum;
        }, 0)
      });
    } catch (err) {
      console.error("error retrieving subscriptions", err);
    }
  }

  render() {
    const placeholderContainer = {
      marginLeft: "-1rem",
      marginRight: "-1rem"
    };

    return (
      <div style={placeholderContainer}>
        <Subscribe
          usedCredits={this.state.usedCredits}
          auth={this.props.auth}
          onNewSub={this.getSubs}
        />
        <SubscriptionsPage
          history={this.props.history}
          subs={this.state.subs}
          auth={this.props.auth}
          onChange={this.getSubs}
        />
      </div>
    );
  }
}
