import React from 'react';
import moment = require('moment');

export function formatTimestamp (ts: number) {
    return moment(ts).format('L LT');
}

export function getNotifyOnString  (notifyOn: string) {
    const data: {
        [notifyOn: string]: string
    } = {
        'changed': 'there are changes',
        'all': 'every time we check'
    }
    return data[notifyOn];
};

export function getSearchTerm  (config: any){
    const getItem = ()=>{
        return config.searchTerm || config.nzbn;
    };

    return `${getItem()}`;
};

export function getKindString (config: any) {
    const kinds: {
        [kind: string]: string
    } = {
        'MBIE_Entity': 'NZBN',
        'MBIE_Person': 'Person',
        'MBIE_Trademark': 'Trademark Owner'
    };

    return kinds[config.kind];
};

export function getIcon (config: any) {
    const classNames: {
        [kind: string]: string
    } = {
        'MBIE_Entity': 'building outline icon',
        'MBIE_Person': 'user icon',
        'MBIE_Trademark': 'lock icon'
    };

    const className = classNames[config.kind];

    return <i className={className}></i>;
}