import { subscribePlan } from './PlanSub'
import StripeCheckout, { Token } from 'react-stripe-checkout'
import React from 'react'
import clientConfig from '../config'
import { get, find } from 'lodash'

import { Select, DropdownProps } from 'semantic-ui-react'

export class ManagePlan extends React.Component<any, any> {
  key: string

  constructor(props: any) {
    super(props)

    this.key = clientConfig().STRIPE_PUBLISHABLE_KEY

    this.state = {
      showConfirm: false,
      showPayButton: false,
      selectedPlan: '',
      plans: [
        {
          text: 'Free',
          key: 0,
          value: clientConfig().STRIPE.PLANS.FREE,
        },
        {
          text: 'Standard',
          key: 1,
          value: clientConfig().STRIPE.PLANS.STANDARD,
        },
        {
          text: 'High',
          key: 2,
          value: clientConfig().STRIPE.PLANS.HIGH,
        },
      ],
    }

    this.onToken = this.onToken.bind(this)
    this.updateSelectedPlan = this.updateSelectedPlan.bind(this)
    this.selectPlan = this.selectPlan.bind(this)
    this.toggleConfirm = this.toggleConfirm.bind(this)
  }

  async onToken(token: Token) {
    await subscribePlan(
      this.props.auth.getAccessToken(),
      this.state.selectedPlan.value,
      token
    )
  }

  updateSelectedPlan(
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) {
    const selectedPlan = find(data.options, { value: data.value })
    this.setState({
      selectedPlan,
      showConfirm: false,
      showPayButton: false,
    })
  }

  toggleConfirm() {
    const requiresPayment = this.requiresPayment()

    this.setState({
      showConfirm: !this.state.showConfirm,
      showPayButton: requiresPayment,
    })
  }

  requiresPayment() {
    return this.state.selectedPlan.value !== clientConfig().STRIPE.PLANS.FREE
  }

  async selectPlan() {
    await subscribePlan(
      this.props.auth.getAccessToken(),
      this.state.selectedPlan.value
    )
  }

  render() {
    const planName = this.props.plans.reduce(
      (accumulator: string, plan: any) => {
        return plan.plan.nickname
      },
      'Loading...'
    )

    const email = get(this.props, 'user.email', undefined)
    const description = `Monthly - ${this.state.selectedPlan.text}`

    return (
      <div>
        <h2>Change Plan</h2>
        <p>Your current plan is: {planName}</p>
        <p>
          Change to:
          <Select
            placeholder="Select Plan"
            onChange={this.updateSelectedPlan}
            options={this.state.plans}
          />
        </p>
        <p>
          {this.state.showConfirm ? (
            <div>
              {this.state.showPayButton ? (
                <StripeCheckout
                  currency="NZD"
                  label="Proceed with payment"
                  panelLabel="Subscribe"
                  email={email}
                  zipCode={true}
                  billingAddress={true}
                  name="Watch and Notify Me"
                  description={description}
                  token={this.onToken}
                  stripeKey={this.key}
                />
              ) : (
                <button
                  className="ui button positive"
                  onClick={this.selectPlan}
                >
                  Confirm change to {this.state.selectedPlan.text}
                </button>
              )}
              <button
                className="ui button negative"
                onClick={this.toggleConfirm}
              >
                Cancel
              </button>
            </div>
          ) : (
            <div>
              <button
                className="ui button positive basic"
                onClick={this.toggleConfirm}
              >
                Update
              </button>
            </div>
          )}
        </p>
      </div>
    )
  }
}
