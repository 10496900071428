import React from "react";

import * as subUtils from "./SubUtils";

import { Timestamp } from "../utils/Timestamp";
import { NotifyDescription } from "./NotifyDescription";

export class Subscription extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.navMore = this.navMore.bind(this);
  }

  navMore() {
    this.props.history.push(
      "/subscription?id=" + this.props.sub.subscriptionId
    );
  }

  render() {
    return (
      <div className="card">
        <div className="content">
          <div className="header">
            {subUtils.getIcon(this.props.sub.configuration)}
            {this.props.sub.name}
          </div>
          <div className="description">
            <table className="ui definition table">
              <tbody>
                <tr>
                  <td className="two wide column">
                    {subUtils.getKindString(this.props.sub.configuration)}
                  </td>
                  <td>
                    {subUtils.getSearchTerm(this.props.sub.configuration)}
                  </td>
                </tr>
                <tr>
                  <td>Notify</td>
                  <td style={{ wordBreak: "break-word" }}>
                    {this.props.sub.notify.map((notify: any, i: number) => {
                      return (
                        <NotifyDescription recipientSet={notify} key={i} />
                      );
                    })}
                  </td>
                </tr>
                <tr>
                  <td>Created</td>
                  <td style={{ wordBreak: "break-word" }}>
                    <Timestamp>{this.props.sub.created}</Timestamp>
                  </td>
                </tr>
                <tr>
                  <td>Credits</td>
                  <td>
                    {this.props.sub.credits}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="ui bottom attached button" onClick={this.navMore}>
          More
        </div>
      </div>
    );
  }
}
