import React from "react";
import { stringify } from "yamljs";

import qs from "qs";
import Axios from "axios";
import config from "../config";
import * as subUtils from "./SubUtils";

import 'react-gh-like-diff/dist/css/diff2html.min.css';
import { SubscriptionLogs } from "./SubscriptionLogs";
import { UnsubscribeButtons } from "./UnsubscribeButtons";
import { NotifyDescription } from "./NotifyDescription";
import { Timestamp } from "../utils/Timestamp";
import { YamlDiffDisplay } from "./YamlDiffDisplay";
import Auth from "../auth/Auth";

interface IProps {
  location: {
    search: string;
  };

  auth: Auth
}

export class SubscriptionPage extends React.Component<any, any> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      sub: null,
      preFile: "",
      postFile: ""
    };
  }
  
  componentDidMount() {
    if (!this.props.auth.isAuthenticated()) {
      console.log('Not authenticated, saving redirect url', this.props.location.search);
      localStorage.setItem("wn_redirect", '/subscription' + this.props.location.search);
      return;
    }

    const query = qs.parse(this.props.location.search.substring(1));

    if (query.id) {
      this.getSub(query.id);
    }

    if (query.view) {
      const decoded = JSON.parse(window.atob(query.view));
      this.loadDiffs(query.id, decoded.pre, decoded.post);
    }
  }

  async getSub(id: string) {
    const token = this.props.auth.getAccessToken();
    const query = qs.stringify({ id: id });

    const response = await Axios.request({
      method: "GET",
      url: config().BASE_API + "/subscription?" + query,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: "json"
    });

    this.setState({
      sub: response.data[0]
    });
  }

  async getSignedUrl(subId: string, bucketKey: string) {
    const query = qs.stringify({ subId, bucketKey });
    const token = this.props.auth.getAccessToken();

    const response = await Axios.request({
      method: "GET",
      url: config().BASE_API + "/artifacts?" + query,
      headers: {
        Authorization: `Bearer ${token}`
      },
      responseType: "json"
    });

    return response.data;
  }

  async loadDiffs(id: string, pre: string, post: string) {
    const preUrl = await this.getSignedUrl(id, pre);
    const postUrl = await this.getSignedUrl(id, post);

    const preFile = await this.retrieveFile(preUrl.url);
    const postFile = await this.retrieveFile(postUrl.url);

    this.setState({
      preFile: stringify(preFile, 10, 4),
      postFile: stringify(postFile, 10, 4)
    });
  }

  async retrieveFile(signedUrl: string) {
    const response = await Axios.get(signedUrl);
    return response.data;
  }

  render() {
    return (
      <div className="row">
        <div className="column">
          <h2>Subscription</h2>
          {!this.state.sub ? (
            <p>Loading...</p>
          ) : (
            <div>
              <h3>
                {subUtils.getIcon(this.state.sub.configuration)}
                {this.state.sub.name}
              </h3>
              <table className="ui definition table">
                <tbody>
                  <tr>
                    <td className="two wide column">
                      {subUtils.getKindString(this.state.sub.configuration)}
                    </td>
                    <td>
                      {subUtils.getSearchTerm(this.state.sub.configuration)}
                    </td>
                  </tr>
                  <tr>
                    <td>Notify</td>
                    <td style={{ wordBreak: "break-word" }}>
                      {this.state.sub.notify.map((notify: any, i: number) => {
                        return (
                          <NotifyDescription recipientSet={notify} key={i} />
                        );
                      })}
                    </td>
                  </tr>
                  <tr>
                    <td>Created</td>
                    <td style={{ wordBreak: "break-word" }}>
                      <Timestamp>{this.state.sub.created}</Timestamp>
                    </td>
                  </tr>
                  <tr>
                    <td>Credits</td>
                    <td>
                      {this.state.sub.credits}
                    </td>
                  </tr>
                </tbody>
              </table>
              <UnsubscribeButtons
                sub={this.state.sub}
                history={this.props.history}
                auth={this.props.auth}
              />

              <YamlDiffDisplay
                pre={this.state.preFile}
                post={this.state.postFile}
              />

              {this.state.sub && this.state.sub.logs ? (
                <SubscriptionLogs
                  sub={this.state.sub}
                  logs={this.state.sub.logs}
                />
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
