import axios from 'axios';
import config from '../config';

export async function subscribePlan(
  bearerToken: string,
  plan: string,
  stripeToken?: any
) {
  const response = await axios.request({
    url: config().BASE_API + '/plan',
    method: 'POST',
    headers: {
      Authorization: `Bearer ${bearerToken}`
    },
    data: JSON.stringify({
      plan: plan,
      token: stripeToken
    })
  });

  console.log('change plan response', response);
}

export async function getPlan(token: string) {
  const response = await axios.request({
    url: config().BASE_API + '/plan',
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`
    }
  });

  return response.data;
}
