import React from "react";
import { Timestamp } from "../utils/Timestamp";

import { get as _get } from "lodash";
import ISubLog from "../../server/subscription/ISubLog";

export class SubscriptionLogs extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const sortFn = (a: ISubLog, b: ISubLog) => {
      return (a.timestamp - b.timestamp) * -1;
    };


    return (
      <table className="ui table">
        <thead>
          <td colSpan={2}>History</td>
        </thead>
        <tbody>
          {this.props.logs.sort(sortFn).map((log: any) => {
            const view = window.btoa(JSON.stringify({
              pre: _get(log, "custom.files.pre", ""),
              post: _get(log, "custom.files.post", ""),
            }));

            const showLink = `/subscription?id=${
              this.props.sub.subscriptionId
            }&view=${view}`;

            return (
              <tr>
                <td>
                  <Timestamp>{log.timestamp}</Timestamp>
                </td>
                <td> {log.message} </td>
                <td>
                  {" "}
                  {log.custom && log.custom.files ? (
                    <a href={showLink} className="ui button basic mini">
                      Show
                    </a>
                  ) : (
                    ""
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }
}
