import React from "react";

import { Subscription } from "./Subscription";
import { ISubscription } from "../../server/subscription/ISubscription";

interface IProps {
  subs: ISubscription[];
  onChange: () => void;
  history: any;
}

export class SubscriptionsPage extends React.Component<any, any> {
  constructor(props: IProps) {
    super(props);
  }

  sortFn(a: ISubscription, b: ISubscription) {
    return a.created - b.created;
  }

  render() {
    const subPageStyle = {
      paddingTop: "20px"
    };

    return (
      <div className="row" style={subPageStyle}>
        <div className="column">
          <h2 className="ui header">Subscriptions</h2>
          <div className="ui cards">
            {this.props.subs.sort(this.sortFn).map((sub: any, i: number) => {
              return (
                <Subscription
                  history={this.props.history}
                  key={i}
                  sub={sub}
                  auth={this.props.auth}
                  onChange={this.props.onChange}
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
