import React from "react";
import { ExplainWhy } from "./ExplainWhy";
import { ExplainHow } from "./ExplainHow";
import { Pricing } from "./Pricing";

export class Home extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  async componentDidUpdate (oldProps: any) {
    if (this.props.auth.authenticated) {

      const redirect = localStorage.getItem("wn_redirect");

      console.log("redirecting to", redirect);
      if (redirect && redirect.length > 0) {
        localStorage.setItem("wn_redirect", "");

        setTimeout(()=>{
          console.log('going to redirect');
          this.props.history.push(redirect);
        }, 3000);
      }
    }
  }

  render() {
    return (
      <div>
        <ExplainWhy />
        <ExplainHow />
        <Pricing />
      </div>
    );
  }
}
