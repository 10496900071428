import React from "react";

import Auth from "../auth/Auth";
import { Subscriptions } from "../subscriptions/Subscriptions";
import { Header } from "./Header";
import { Nav } from "./Nav";

import { BrowserRouter, Route, Link } from "react-router-dom";
import { Home } from "./Home";
import { Plan } from "./Plan";
import { SubscriptionPage } from "../subscriptions/SubscriptionPage";
import { UnsubscribePage } from "../subscriptions/UnsubscribePage";
import { Privacy } from "./Privacy";

export class Main extends React.Component<any, any> {
  auth: Auth;

  constructor(props: any) {
    super(props);
    this.auth = new Auth();

    this.state = {
      authenticated: this.auth.isAuthenticated()
    };
  }

  async componentDidMount() {
    const authenticated = await this.auth.handleAuthentication();

    this.setState({
      authenticated: authenticated
    });
  }

  render() {
    const mainStyle = {
      paddingTop: "20px"
    };

    return (
      <BrowserRouter>
        <div>
          <div className="ui column grid container" style={mainStyle}>
            <Header auth={this.auth} />
            <Nav auth={this.auth} />
            <Route path="/privacy" component={Privacy} exact />

            <Route path="/" render={(props: any) => {
              return <Home history={props.history} auth={this.auth}></Home>
            }} exact />

            <Route
              path="/plan"
              render={(props: any) => {
                return <Plan auth={this.auth} />;
              }}
              exact
            />

            <Route
              path="/unsubscribe"
              exact
              render={(props: any) => {
                return <UnsubscribePage history={props.history} auth={this.auth} location={props.location} />
              }}
            />

            <Route
              path="/subscriptions"
              exact
              render={(props: any) => {
                return this.auth.isAuthenticated() ? (
                  <Subscriptions history={props.history} auth={this.auth} />
                ) : (
                    ""
                  );
              }}
            />

            <Route
              path="/subscription"
              render={(props: any) => {
                return (
                  <SubscriptionPage
                    history={props.history}
                    auth={this.auth}
                    location={props.location}
                  />
                );
              }}
              exact
            />
          </div>
        </div>
      </BrowserRouter>
    );
  }
}
