import React from 'react';

export function Pricing() {
  const priceStyle = {
    textAlign: 'center' as 'center'
  };

  return (
    <div>
      <h2 className="ui header">Pricing</h2>
      <p>Billed monthly in NZD excl GST.</p>
      <div className="ui horizontal segments" style={priceStyle}>
        <div className="ui segment">
          <h2>Free</h2>
          <h3>$0</h3>
          <p>5 credits</p>
        </div>
        <div className="ui segment">
          <h2>Standard</h2>
          <h3>$10</h3>
          <p>20 credits</p>
        </div>
        <div className="ui segment">
          <h2>High</h2>
          <h3>$100</h3>
          <p>250 credits</p>
        </div>
      </div>
      <p>Subscriptions are retrieved on a daily basis.</p>
      <p>
        Larger quantities or more frequent retrievals are available. Please get
        in touch support@watchandnotify.me
      </p>
    </div>
  );
}
