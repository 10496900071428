import React from 'react';
import { Login } from '../auth/Login';

export function Header (props: any) {
    return (
        <div className="row">
            <div className="ui two item secondary menu">
                <h1>Watch and Notify Me // NZ Companies</h1>
                <Login auth={props.auth}></Login> 
            </div>
        </div>
    );
}