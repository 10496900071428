import React from 'react';

import { Link } from 'react-router-dom';

export function Nav(props: any) {
  return (
    <div className="row borderless">
      <div className="ui secondary compact menu">
        <div className="item">
          <Link to="/">Home</Link>
        </div>
        <div className="item">
          <Link to="/subscriptions">Subscriptions</Link>
        </div>

        {
          props.auth.isAuthenticated() ?
            <div className="item">
              <Link to="/plan">Plan</Link>
            </div> : ''
        }
        <div className="item">
          <Link to="/privacy">Privacy</Link>
        </div>
      </div>
    </div>
  );
}