import React from "react";

import { SubscribeMbie } from "./SubscribeMbie";
import { UserPlan } from "./UserPlan";

export class Subscribe extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className="row">
        <div className="column">
          <UserPlan auth={this.props.auth}>
            <SubscribeMbie
              usedCredits={this.props.usedCredits}
              auth={this.props.auth}
              onNewSub={this.props.onNewSub}
            />
          </UserPlan>
        </div>
      </div>
    );
  }
}
