import React from "react";

import Auth from "./Auth";

interface IProps {
  auth: Auth;
}

export class Login extends React.Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
  }

  login() {
    this.props.auth.login();
  }

  logout() {
    this.props.auth.logout();
    location.reload();
  }

  render() {
    return (
      <div className="right menu">
        {this.props.auth.isAuthenticated() ? (
          <button className="ui button" onClick={this.logout}>
            Logout
          </button>
        ) : (
          <button className="ui button" onClick={this.login}>
            Login / Register
          </button>
        )}
      </div>
    );
  }
}
