import React from 'react'

export function ExplainHow() {
  return (
    <div className="ui three steps">
      <div className="step">
        <i className="wpforms icon" />
        <div className="content">
          <div className="title">You Subscribe</div>
          <div className="description">to a business or person</div>
        </div>
      </div>
      <div className="step">
        <i className="eye icon" />
        <div className="content">
          <div className="title">We Watch</div>
          <div className="description">daily for updates</div>
        </div>
      </div>
      <div className="step">
        <i className="bullhorn icon" />
        <div className="content">
          <div className="title">We Notify You</div>
          <div className="description">when something changes</div>
        </div>
      </div>
    </div>
  )
}
