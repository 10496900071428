import React from "react";
import { ReactGhLikeDiff } from "react-gh-like-diff";

interface IProps {
  pre: string
  post: string
}

export class YamlDiffDisplay extends React.Component<any, any> {

  constructor (props: IProps) {
    super(props);

  }

  render() {
    const diffOptions = {
      showFiles: false
    };

    return (
      <div>
      {
        this.props.pre.length === 0 ?
        '' : 
        <div>
          {this.props.pre === this.props.post ? (
            <div>
              <p>No changes were detected, this is the result we got:</p>
              <pre>{this.props.pre}</pre>
            </div>
          ) : (
            <ReactGhLikeDiff
              past={this.props.pre}
              current={this.props.post}
              options={diffOptions}
            />
          )}
        </div>
      }
      </div>
    )
  }

}