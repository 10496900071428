import React from "react";
import qs from "qs";
import Auth from "../auth/Auth";
import Axios from "axios";
import config from "../config";

interface IProps {
  auth: Auth;

  location: {
    search: string;
  };

  history: any;
}

export class UnsubscribePage extends React.Component<any, any> {
  constructor(props: IProps) {
    super(props);

    this.state = {
      id: "",
      token: "",
      submitting: false,
      submitSuccess: false
    };

    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const query = qs.parse(this.props.location.search.substring(1));

    this.setState({
      id: query.id,
      token: query.token
    });

    if (this.props.auth.isAuthenticated()) {
      return this.props.history.push(`/subscription?id=${query.id}`);
    }
  }

  async submit() {
    this.setState({
      submitting: true
    });

    const response = await Axios({
      method: "POST",
      url: config().BASE_API + "/unsubscribe",
      data: JSON.stringify({
        id: this.state.id,
        token: this.state.token
      })
    });

    this.setState({
      submitSuccess: true
    });

    console.log(response);
  }

  render() {
    const subPageStyle = {
      paddingTop: "20px"
    };

    return (
      <div className="row" style={subPageStyle}>
        <div className="column">
          <h2 className="ui header">Unsubscribe</h2>

          {this.state.submitSuccess ? (
            <p>Successfully unsubscribed</p>
          ) : (
            <button
              className="ui button negative"
              onClick={this.submit}
              disabled={this.state.submitting}
            >
              {this.state.submitting ? (
                <div className="ui mini active inline loader" />
              ) : (
                ""
              )}
              Click here to confirm cancellation
            </button>
          )}

          <p>Click to instantly unsubscribe, or sign in for more detail.</p>
          <p>You can also unsubscribe by emailing unsubscribe@watchandnotify.me</p>
        </div>
      </div>
    );
  }
}
