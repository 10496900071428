import auth0 from "auth0-js";

import clientConfig from "../config";

export default class Auth {
  auth0: auth0.WebAuth;
  authenticated: boolean;

  constructor() {
    this.auth0 = new auth0.WebAuth({
      domain: clientConfig().AUTH0_MGMT.API_DOMAIN,
      clientID: clientConfig().AUTH0_CLIENT_ID,
      redirectUri: window.location.origin,
      responseType: "token id_token",
      scope: "openid email user_metadata profile retrieve:subscriptions",
      audience: clientConfig().AUTH0_MGMT.AUDIENCE
    });

    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.authenticated = false;
  }

  getAccessToken() {
    return localStorage.getItem("access_token");
  }

  async handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);
        } else if (err) {
          console.log(err);
        }

        return resolve(this.isAuthenticated());
      });
    });
  }

  setSession(authResult: auth0.Auth0DecodedHash) {
    // Set the time that the Access Token will expire at

    let expiresAt = JSON.stringify(
      //@ts-ignore
      authResult.expiresIn * 1000 + new Date().getTime()
    );

    if (authResult.accessToken) {
      localStorage.setItem("access_token", authResult.accessToken);
    }
    //@ts-ignore
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
  }

  logout() {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    return this.isAuthenticated();
  }

  isAuthenticated() {
    // Check whether the current time is past the
    // Access Token's expiry time
    //@ts-ignore
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    this.authenticated = new Date().getTime() < expiresAt;
    return this.authenticated;
  }

  login() {
    this.auth0.authorize();
  }
}
