import React from 'react';

import axios from 'axios';
import config from '../config';

export class SubscribeMbie extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      isSubmitting: false,
      display: {
        nzbn: false,
        person: false,
        trademark: false,
        name: false
      },
      nzbn: '',
      searchTerm: '',
      tmSearchTerm: '',
      name: '',
      entitlement: 0
    };

    this.inputChanged = this.inputChanged.bind(this);
    this.submit = this.submit.bind(this);
  }

  async submit(event: React.MouseEvent) {
    this.setState({
      isSubmitting: true
    });

    event.preventDefault();
    const token = this.props.auth.getAccessToken();

    let filled = 0;

    if (this.state.nzbn.length > 0) filled++;
    if (this.state.searchTerm.length > 0) filled++;
    if (this.state.tmSearchTerm.length > 0) filled++;

    if (filled > 1) {
      alert('Only one of NZBN/Person/Trademark can be filled');
      return;
    }

    if (filled === 0) {
      alert('One of NZBN/Person/Trademark must be filled');
      return;
    }

    let fetchMethod = '';
    let configVar = '';
    let term = '';

    if (this.state.nzbn.length > 0) {
      fetchMethod = 'MBIE_Entity';
      configVar = 'nzbn';
      term = this.state.nzbn;
    }

    if (this.state.searchTerm.length > 0) {
      fetchMethod = 'MBIE_Person';
      configVar = 'searchTerm';
      term = this.state.searchTerm;
    }

    if (this.state.tmSearchTerm.length > 0) {
      fetchMethod = 'MBIE_Trademark';
      configVar = 'searchTerm';
      term = this.state.tmSearchTerm;
    }

    try {
      const response = await axios.request({
        method: 'PUT',
        url: config().BASE_API + '/subscription',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        responseType: 'json',
        data: JSON.stringify({
          name: this.state.name,
          fetchMethod: fetchMethod,
          configuration: {
            kind: fetchMethod,
            [configVar]: term
          },
          compareMethod: 'string',
          notifyOn: 'changed'
        })
      });

      console.log('Successfully added the subscription', response.status);

      this.props.onNewSub();
    } catch (err) {
      console.log('Failed to add the subscription', err);
    } finally {
      this.setState({
        isSubmitting: false
      });
    }
  }

  inputChanged(event: React.ChangeEvent<HTMLInputElement>) {
    this.setState({
      [event.target.name]: event.target.value
    });
  }

  displaySection(event: React.MouseEvent, section: string) {
    event.preventDefault();
    this.setState({
      display: {
        nzbn: section === 'nzbn',
        person: section === 'person',
        trademark: section === 'trademark',
        name: ['nzbn', 'person', 'trademark'].indexOf(section) > -1
      }
    });
  }

  render() {
    const entitlement = this.props.plans.reduce((accumulator: number, plan: any)=>{
      accumulator += parseInt(plan.plan.metadata.credits, 10)
      return accumulator
    }, 0)

    return (
      <div>
        <form className="ui form segments">
          <div className="ui segment">
            <h2 className="ui header">New Subscription</h2>
          </div>
          <div className="ui segment">
            <p>
              {this.props.usedCredits} / {entitlement} credits used
            </p>
          </div>
          <div className="ui segment">
            <div className="ui two column very relaxed grid">
              <div className="column">
                <h3>
                  NZBN
                </h3>
                <p>Monitor changes to an MBIE entity - companies, charities etc.</p>
                <p>Useful for</p>
                <ul>
                  <li>Checking if a company's shareholder or directors change</li>
                  <li>Tracking changes in business information</li>
                </ul>
                <button className="ui button positive basic" onClick={(event) => this.displaySection(event, 'nzbn')}>Add New</button>
              </div>
              <div className="column">
                <h3>
                  Person
                </h3>
                <p>Monitor changes to a person's search term</p>
                <p>Useful for</p>
                <ul>
                  <li>Getting updates to their details</li>
                  <li>Getting informed on new directorships/shareholdings</li>
                </ul>
                <button className="ui button positive basic" onClick={(event) => this.displaySection(event, 'person')}>Add New</button>
              </div>
            </div>
          </div>
          {this.state.display.name ? (
            <div className="ui segment">
              <div className="ui one column very relaxed grid">
                <div className="column">
                  <div className="field">
                    <label>Subscription Name*</label>
                    <input
                      placeholder="Name"
                      type="text"
                      name="name"
                      onChange={this.inputChanged}
                    />
                    <p>For your purposes only</p>
                  </div>
                </div>
                <div className="column">
                  {this.state.display.nzbn ? (
                    <SubscribeNzbn inputChanged={this.inputChanged} />
                  ) : (
                    ''
                  )}
                  {this.state.display.person ? (
                    <SubscribePerson inputChanged={this.inputChanged} />
                  ) : (
                    ''
                  )}
                  {this.state.display.trademark ? (
                    <SubscribeTrademark inputChanged={this.inputChanged} />
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
          {this.state.display.name ? (
            <div className="ui segment">
              {this.state.isSubmitting ? (
                <button className="ui button" disabled>
                  <div className="ui mini active inline loader" />
                  Submitting
                </button>
              ) : (
                <button className="ui button" onClick={this.submit}>
                  Submit
                </button>
              )}
            </div>
          ) : (
            ''
          )}
        </form>
      </div>
    );
  }
}

function SubscribeNzbn(props: any) {
  return (
    <div className="field">
      <label>
        <i className="building outline icon" />
        NZ Business Number (1 credit)
      </label>
      <input
        placeholder="NZBN"
        type="number"
        name="nzbn"
        onChange={props.inputChanged}
      />
      <p>
        Search:{' '}
        <a href="https://www.nzbn.govt.nz/" target="_blank">
          https://www.nzbn.govt.nz/
        </a>
      </p>
    </div>
  );
}

function SubscribePerson(props: any) {
  return (
    <div className="field">
      <label>
        <i className="user icon" />
        Person Name (1 credit)
      </label>
      <input
        placeholder="Search term"
        type="text"
        name="searchTerm"
        onChange={props.inputChanged}
      />
      <p>Results as per shareholder/director search:</p>
      <a
        href="https://companies-register.companiesoffice.govt.nz"
        target="_blank"
      >
        https://companies-register.companiesoffice.govt.nz
      </a>
    </div>
  );
}

function SubscribeTrademark(props: any) {
  return (
    <div className="field">
      <label>
        <i className="lock icon" />
        Trademark Owner (5 credits)
      </label>
      <input
        placeholder="Search term"
        type="text"
        name="tmSearchTerm"
        onChange={props.inputChanged}
      />
      <p>Results as per owner search in Trademarks register:</p>
      <a href="https://www.iponz.govt.nz/manage-ip" target="_blank">
        https://www.iponz.govt.nz/manage-ip
      </a>
    </div>
  );
}
