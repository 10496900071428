import React from 'react';

export function NotifyDescription (props: any) {
    const data: string[] = [];

    if (props.recipientSet.to) data.push('To: ' + props.recipientSet.to);
    if (props.recipientSet.cc) data.push('CC: ' + props.recipientSet.cc);
    if (props.recipientSet.bcc) data.push('BCC: ' + props.recipientSet.bcc);

    return <div>
        {
            data.map((item)=>{
                return <p>{item}</p>;
            })
        }
    </div>
}