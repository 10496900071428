import React from "react";
import axios from "axios";
import config from "../config";

export class UnsubscribeButtons extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      confirm: false
    };

    this.cancel = this.cancel.bind(this);
    this.askConfirm = this.askConfirm.bind(this);
    this.cancelConfirm = this.cancelConfirm.bind(this);
  }

  async cancel() {
    const token = this.props.auth.getAccessToken();

    const response = await axios.request({
      method: "DELETE",
      url: config().BASE_API + "/subscription",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json"
      },
      responseType: "json",
      data: JSON.stringify(this.props.sub)
    });

    this.props.history.push('/subscriptions');
    this.cancelConfirm();
  }

  askConfirm() {
    this.setState({
      confirm: true
    });
  }

  cancelConfirm() {
    this.setState({
      confirm: false
    });
  }

  render() {
    return (
      <div>
        {this.state.confirm ? (
          <div>
            <div className="ui button negative" onClick={this.cancel}>
              Confirm Unsubscribe
            </div>
            <div
              className="ui button secondary basic"
              onClick={this.cancelConfirm}
            >
              Cancel
            </div>
          </div>
        ) : (
          <div className="ui button negative basic" onClick={this.askConfirm}>
            Unsubscribe
          </div>
        )}
      </div>
    );
  }
}
