import React from "react";
import { getPlan } from "../main/PlanSub";

export class UserPlan extends React.Component<any, any> {

  constructor (props: any) {
    super(props);

    this.state = {
      plan: [],
      user: null
    }
  }

  async componentDidMount () {
    const plan = await getPlan(this.props.auth.getAccessToken());

    this.setState({
      plan: plan.subs,
      user: plan.user
    });
  }

  render () {
    const { children } = this.props;

    const childrenWithProps = React.Children.map(children, (child: any) => {
      return React.cloneElement(child, { 
        plans: this.state.plan,
        user: this.state.user 
      });
    });

    return <div>{childrenWithProps}</div>
  }

}