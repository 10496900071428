import React from 'react';

export function ExplainWhy () {
    const segmentStyle = {
        width: '100%'
    };

    return (
        <div className="row">
            <div className="ui basic segment" style={segmentStyle}>
              <ul>
                  <li>
                      Track shareholders and directors in a contract or agreement e.g. shareholders agreement
                  </li>
              </ul>
              <ul>
                  <li>
                      Monitor a private company's shareholdings
                  </li>
              </ul>
              <ul>
                  <li>
                      Get alerts when competitors register new businesses
                  </li>
              </ul>
            </div>
        </div>
    );
}