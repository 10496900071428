import React from 'react';

import { Pricing } from './Pricing';
import { UserPlan } from '../subscriptions/UserPlan';
import { ManagePlan } from './ManagePlan';

export class Plan extends React.Component<any, any> {

  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div>
        <Pricing />
        <UserPlan auth={this.props.auth}>
          <ManagePlan auth={this.props.auth}></ManagePlan>
        </UserPlan>
      </div>
    );
  }
}
